import styled from "@emotion/styled";
import { graphql } from "gatsby";
import PropTypes from "prop-types";
import { complement, isEmpty, map } from "ramda";
import React from "react";

import CaseGrid from "../../components/CaseGrid";
import ContactHighlight from "../../components/ContactHighlight";
import ContentWrapper from "../../components/ContentWrapper";
import Heading from "../../components/Heading";
import Hero from "../../components/Hero/v6";
import Introduction from "../../components/Introduction";
import Methods from "../../components/Methods";
import Modular from "../../components/Modular";
import PageLink from "../../components/PageLink";
import SEO from "../../components/SEO";
import Ticker from "../../components/Ticker";
import { EXPERTISES_PATH } from "../../constants";
import theme from "../../theme";
import { expertise } from "../../types";
import formatCase from "../../utils/formatters/formatCase";

const notEmpty = complement(isEmpty);

const methodsMap = (methods) =>
  map(
    (method) => ({
      id: method.trim(),
      name: method.trim(),
    }),
    methods.split(",")
  );

const StyledHeading = styled(Heading)`
  max-width: ${theme.spacing("grid.6")};
`;
const StyledIntroduction = styled(Introduction)`
  max-width: ${theme.spacing("grid.6")};
  margin-bottom: ${theme.spacing("l")};
`;

const Expertise = ({
  data: {
    data: { expertise, allExpertises },
  },
}) => {
  const {
    title,
    pageTitle,
    herocase,
    content,
    tagline,
    relatedCases,
    clients,
    contactHighlight,
    seoMetaTags,
    methods,
    description,
    descriptionHeading,
  } = expertise;

  const formattedClients = clients.map((client) => client.name);

  const formattedRelatedCases = relatedCases.map((caseItem) =>
    formatCase(caseItem)
  );

  const filteredExpertises = allExpertises.filter(
    (expertise) => expertise.title != title
  );

  return (
    <>
      <SEO tags={seoMetaTags} />
      <Hero
        title={pageTitle || title}
        caseItem={herocase && formatCase(herocase)}
        introduction={
          <Introduction style={{ color: "white" }}>{tagline}</Introduction>
        }
        content={
          <>
            WE DOEN OOK:
            {filteredExpertises.map((expertise) => (
              <PageLink
                key={expertise.title}
                url={`${EXPERTISES_PATH}/${expertise.slug}`}
                title={expertise.title}
                color={"text.light"}
              />
            ))}
          </>
        }
      />

      <ContentWrapper columns={10}>
        {descriptionHeading && (
          <StyledHeading size={2}>{descriptionHeading}</StyledHeading>
        )}
        {description && (
          <StyledIntroduction html>{description}</StyledIntroduction>
        )}

        <Methods
          title={<Heading size={4}>We helpen je met...</Heading>}
          methods={methodsMap(methods)}
        />
      </ContentWrapper>

      {content.filter(notEmpty).map((item) => (
        <Modular key={item.id || item.title} {...item} />
      ))}

      {relatedCases.length > 0 && (
        <CaseGrid
          heading={
            <StyledHeading size={2}>
              Dit werk versterken we al met {expertise.title}
            </StyledHeading>
          }
          payOff={
            <PageLink
              url={"/werk"}
              title={"Laat je inspireren door ons werk"}
            />
          }
          backgroundColor={"background.lighter"}
          items={formattedRelatedCases}
        />
      )}

      {contactHighlight && <ContactHighlight {...contactHighlight} />}

      {formattedClients.length > 0 && <Ticker items={formattedClients} />}
    </>
  );
};

Expertise.propTypes = {
  data: PropTypes.shape({
    data: PropTypes.shape({
      expertise: PropTypes.shape(expertise).isRequired,
      allExpertises: PropTypes.array,
    }).isRequired,
  }).isRequired,
};

export default Expertise;

export const query = graphql`
  query getExpertise($slug: String!) {
    data: dato {
      allExpertises {
        id
        title
        slug
      }
      expertise(filter: { slug: { eq: $slug } }) {
        seoMetaTags: _seoMetaTags {
          tag
          attributes
          content
        }
        title
        pageTitle
        methods
        descriptionHeading
        description(markdown: true)
        tagline
        slug
        heroImage {
          data: responsiveImage(
            imgixParams: { fit: crop, h: 710, fm: jpg, auto: format }
          ) {
            ...responsiveImageFragment
          }
        }
        herocase {
          ...caseFragment
        }
        content {
          ...basicTitleDescriptionFragment
          ...callToActionFragment
          ...imageFragment
          ...videoFragment
          ...quoteFragment
          ...textColumnFragment
          ...textImageFragment
          ...imageCarouselFragment
          ...featuredContentFragment
        }
        contactHighlight {
          ...contactHighlightFragment
        }
        clients {
          name
        }
        relatedCases {
          ...caseFragment
        }
      }
    }
  }
`;
